<template>
    <main class="content">
    <div class="container">
      <div class="info-block redeem-cashback">
        <header>
          <div class="logo">
            <img src="./../../assets/images/logo.png"  alt="BSH"/>
          </div>
          <div class="brand-logos d-flex">
            <div class="logo">
              <img src="./../../assets/images/bosch-logo.png" alt="Bosch" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/siemens-logo.png" alt="siemens" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/neff.png" alt="neff" />
            </div>
          </div>
        </header>
        <div class="info-container">
          <h2>Thank you for your submission</h2>
          <p>Thank you for submitting your cashback claim and for remaining a loyal customer. Your claim has been received.</p>
          <p>You will receive an automated email. Please check your junk/spam inbox.</p>
        </div>
        <footer>
          <p>
            <a href="https://www.bosch-home.co.uk/store/terms-of-website-use" class="mr-3">Term of use </a>
            <a href="https://www.bosch-home.co.uk/data-protection-information">Data Protection Policy</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
export default{}
</script>
